import { Controller } from '@hotwired/stimulus'

const uploadButtonDisabledClasses = [
  'cursor-default',
  'select-none',
]
const uploadButtonHoverClasses = [
  'hover:text-gray-900',
  'dark:hover:text-gray-300'
]

export default class extends Controller {
  static targets = [
    'uploader',
    'uploadButton'
  ]

  static values = {
    maxFiles: {
      type: Number,
      default: 1
    }
  }

  connect() {
    window.requestAnimationFrame(() => {
      this.handleFilesAdded()
    })
  }

  uploadButtonDisabled = false

  disableUploadButton() {
    this.uploadButtonDisabled = true
    this.uploadButtonTarget.classList.add(...uploadButtonDisabledClasses)
    this.uploadButtonTarget.classList.remove(...uploadButtonHoverClasses)
  }

  enableUploadButton() {
    this.uploadButtonDisabled = false
    this.uploadButtonTarget.classList.remove(...uploadButtonDisabledClasses)
    this.uploadButtonTarget.classList.add(...uploadButtonHoverClasses)
  }

  openUploadPopup() {
    if (!this.uploadButtonDisabled) {
      this.uploaderTarget.openUploadPopup()
    }
  }

  reset() {
    this.uploaderTarget.reset()
    this.enableUploadButton()
  }

  handleFilesAdded() {
    if (this.uploaderTarget.files.length >= this.maxFilesValue) {
      this.disableUploadButton()
    }
  }

  handleFilesRemoved() {
    this.enableUploadButton()
  }
}
